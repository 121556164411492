body {
  margin: 0;
  background-color: #fafbff;
}

body * {
  font-family: 'Montserrat', 'Monument Extended', 'Open Sans', sans-serif;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.control-dots {
  position: absolute;
  margin: 10px 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.premium-site * {
  font-family: 'Poppins';
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3px;
  border-radius: 10%;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

/* Custom Chakra Checkbox styling */
.chakra-checkbox__control {
  width: 20px !important;
  height: 20px !important;
  border-radius: 5px !important;
}

.chakra-checkbox__control[data-hover] {
  background: inherit;
}

.chakra-checkbox__control[data-focus] {
  box-shadow: none !important;
}
