.carousel-container {
  margin-top: 1rem;
}

.carousel {
  display: flex;
  flex-direction: row;
}

.item {
  transition: transform 500ms ease-in-out;
  will-change: transform;
  border-radius: 20px;
}

.selected {
  z-index: 1;
}

.selected::before {
  z-index: 2;
  background: linear-gradient(
    to right,
    /* Esta es la dirección del gradiente */ rgba(0, 0, 0, 1),
    /* Negro opaco */ rgba(0, 0, 0, 0) /* Negro transparente */
  );
}

.item-container {
  position: relative;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;
  width: max(32vw, 300px);
  height: max(22vw, 200px);
}

.icon-play-centered {
  position: absolute;
  align-self: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  color: #f9f1f1;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
}

.title-container {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  color: white;
  background-color: 'transparent';
  font-weight: bold;
  z-index: 3;
  /* right: 0; */
  /* padding: 1rem; */
}

.shadow::before {
  border-radius: 20px;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.prev-button,
.next-button {
  position: absolute;
  z-index: 10;
  border: none;
  cursor: pointer;
}

.prev-button {
  transform: rotate(180deg);
  top: 50%;
}

.next-button {
  top: 52%;
}

@media screen and (max-width: 640px) {
  .prev-button {
    left: 1rem;
  }
  .next-button {
    right: 1rem;
  }
}
@media screen and (min-width: 641px) {
  .prev-button {
    left: 6.6rem;
  }
  .next-button {
    right: 6.6rem;
  }
}

.play-button {
  top: 52%;
}

@media screen and (max-width: 640px) {
  .play-button {
    left: 1rem;
  }
}
@media screen and (min-width: 641px) {
  .play-button {
    left: 6.6rem;
  }
}
